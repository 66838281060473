import { httpsCallable } from 'firebase/functions'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../components/Navbar'
import { NewLibraryForm } from '../components/NewLibraryForm'
import { functions } from '../firebase'
import { Group } from '../model/Group'
import { Library } from '../model/Library'

const createLibrary = httpsCallable(functions, "createLibrary");
const getNewLibraryPageData = httpsCallable(functions, "getNewLibraryPageData");

type NewLibraryPageData = {
    groups: Group[];
};

export const NewLibraryPage = () => {

    const navigate = useNavigate();
    const [pageData, setPageData] = useState<NewLibraryPageData>();
    
    useEffect(() => {
        getNewLibraryPageData().then((res) => setPageData(res.data as NewLibraryPageData));
    }, []);

    if(!pageData) {
        return <span>Loading...</span>
    }

    return (
        <div>
            <Navbar/>
            <NewLibraryForm groups={pageData.groups} onSubmit={(data) => {
                createLibrary(data).then((res) => navigate(`/view-library/${(res.data as Library).id}`));
            }}/>
        </div>
    )
}