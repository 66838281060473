import { httpsCallable } from 'firebase/functions'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../components/Navbar'
import { NewGroupForm } from '../components/NewGroupForm'
import { functions } from '../firebase'

const createGroup = httpsCallable(functions, "createGroup");

export const NewGroupPage = () => {

    const navigate = useNavigate();

    return (
        <div>
            <Navbar/>
            <NewGroupForm onSubmit={(data) => {
                createGroup(data).then((res) => navigate(`/view-group/${(res.data as any).id}`));
            }}/>
        </div>
    )
}