import { initializeApp } from 'firebase/app';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectAuthEmulator, getAuth }  from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDZR8F5wgSUAbeL6xV0-IZDup0knPuKaZ8",
  authDomain: "mix-mash-match.firebaseapp.com",
  projectId: "mix-mash-match",
  storageBucket: "mix-mash-match.appspot.com",
  messagingSenderId: "193363963960",
  appId: "1:193363963960:web:3236785afc71c5100236cd",
  measurementId: "G-RTNZLKTHS7"
};

export const firebase = initializeApp(firebaseConfig);
export const functions = getFunctions(firebase, "europe-west2");
export const auth = getAuth(firebase);