import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react'
import { Button } from '../components/form/Button';
import { ModerationQueue } from '../components/ModerationQueue';
import { Navbar } from '../components/Navbar';
import { functions } from '../firebase';
import { Asset } from '../model/Asset';
import { Creation } from '../model/Creation';
import { Group } from '../model/Group';

const getModerationQueues = httpsCallable(functions, "getModerationQueues");
const applyModeration = httpsCallable(functions, "applyModeration");
const typeMap = new Map<string, string>([
    ["Assets", "asset"],
    ["Creations", "creation"],
    ["Groups", "group"],
    ["Users", "profile"],
]);

type ModerationQueues = {
    "Assets": Asset[],
    "Creations": Creation[],
    "Groups": Group[],
    "Users": any[], // TODO: User type
}

export const ModerationQueuePage = () => {

    const [moderationQueues, setModerationQueues] = useState<{[key: string]: any[]}>();

    const [currentType, setCurrentType] = useState("Assets");

    const fetchModerationQueues = () => {

        getModerationQueues()
            .then((res) => {setModerationQueues(res.data as ModerationQueues)})
            .catch(console.log);
    };

    useEffect(fetchModerationQueues, []);

    if (moderationQueues) {
        return (
            <div>
                <Navbar />
                <div className="queueWrapper">
                <div className="assetsToReview">
                        <h2>Refresh Queue</h2>
                        <Button type="button" onClick={fetchModerationQueues}>Refresh</Button>
                    </div>
                    <div className="assetsToReview">
                        <h2> Review Assets</h2>
                        <Button disabled={currentType==="Assets"} type="button" onClick={() => {
                            setCurrentType("Assets");
                        }}>{`Assets (${moderationQueues["Assets"].length})`}</Button>
                    </div>
                    <div className="assetsToReview">
                    <h2> Review Creations</h2>
                        <Button disabled={currentType==="Creations"} type="button" onClick={() => {
                            setCurrentType("Creations");
                        }}>{`Creations (${moderationQueues["Creations"].length})`}</Button>
                    </div>
                    <div className="assetsToReview">
                    <h2> Review Groups</h2>
                    <Button disabled={currentType==="Groups"} type="button" onClick={() => {
                        setCurrentType("Groups");
                    }}>{`Groups (${moderationQueues["Groups"].length})`}</Button>
                    </div>
                    <div className="assetsToReview">
                    <h2> Review Users</h2>
                    <Button disabled={currentType==="Users"} type="button" onClick={() => {
                        setCurrentType("Users");
                    }}>{`Users (${moderationQueues["Users"].length})`}</Button>
                    </div>
                    <div className="assetsToReview">
                    <h2> Review Libraries</h2>
                    <Button disabled={currentType==="Libraries"} type="button" onClick={() => {
                        setCurrentType("Libraries");
                    }}>{`Libraries (${moderationQueues["Libraries"].length})`}</Button>
                    </div>
                    <div className="assetsToReview">
                    <h2> Review Comments</h2>
                    <Button disabled={currentType==="Comments"} type="button" onClick={() => {
                        setCurrentType("Comments");
                    }}>{`Comments (${moderationQueues["Comments"].length})`}</Button>
                    </div>

                    <ModerationQueue type={typeMap.get(currentType)!!} moderationQueue={moderationQueues[currentType]} onSave={(approved, denied) => {
                        applyModeration({ type: currentType, approved, denied })
                            .then(fetchModerationQueues)
                            .catch(console.log);
                    }}></ModerationQueue>
                </div>
            </div>
        )
    } else {
        return <span>Loading...</span>
    }
}