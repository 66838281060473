import React from 'react'
import { Navbar } from '../components/Navbar'
import { Link } from 'react-router-dom'

export const NotFoundPage = () => {
    return (
        <div>
            <Navbar />
            <div className="fourZeroFourWrapper">
                <h1 className="fourZeroFour">404</h1>
                <p className="notFoundText">This page could not be found, return to the main page <Link to="/">here.</Link></p>
            </div>
        </div>
    )
}