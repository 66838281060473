import { signOut } from 'firebase/auth';
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { auth } from '../firebase';

export const LogoutPage = () => {
    useEffect(() => {
        signOut(auth);
    });
    return (
        <Navigate to="/login" />
    )
}