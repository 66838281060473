import React from 'react'
import { RegisterForm, RegistrationDetails } from '../components/RegisterForm'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth, functions } from '../firebase'
import { useNavigate } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { sendEmailVerification } from 'firebase/auth'
import { Navbar } from '../components/Navbar'

const newUser = httpsCallable(functions, "newUser");

export const RegisterPage = () => {

    const navigate = useNavigate();

    const registerUser = (details: RegistrationDetails) => {
        return newUser(details)
            .then(() => signInWithEmailAndPassword(auth, details.email, details.password))
            .then(() => {
                if (auth.currentUser != null)
                    sendEmailVerification(auth.currentUser)
                else {
                    console.log("Not logged in");
                }
                navigate("/");
            })
            .catch(console.log); // TODO: Deal with errors
    };

    return (
    <div>
        <Navbar />
        <RegisterForm onSubmit={registerUser} />
    </div>
    )
}


