import { httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { UserContext } from '../App';
import { CommentForm } from '../components/CommentForm';
import { Navbar } from '../components/Navbar';
import { ViewComment } from '../components/ViewComment';
import { functions } from '../firebase';
import { Asset } from '../model/Asset';
import { Comment } from '../model/Comment';
import { User } from '../model/User';

const getViewAssetPageData = httpsCallable(functions, "getViewAssetPageData");
const addComment = httpsCallable(functions, "addComment");

type ViewAssetPageData = {
    asset: Asset;
    comments: Comment[];
    user: User;
}

export const ViewAssetPage = () => {

    const urlParams = useParams();
    const userContext = useContext(UserContext);
    const [pageData, setPageData] = useState<ViewAssetPageData>();

    useEffect(() => {
        getViewAssetPageData({ id: urlParams.id })
        .then((res) => setPageData(res.data as ViewAssetPageData))
        .catch(console.log); // TODO: Errors
    }, [urlParams.id]);

    if (pageData == null) {
        return <span>Loading...</span>
    }

    if (!pageData.asset) {
        return <div className="fourZeroFourWrapper">
        <h1 className="fourZeroFour">404</h1>
        <p className="notFoundText">This page could not be found, return to the main page <Link to="/">here.</Link></p>
    </div>;
    }

    return (
        <div>
            <Navbar />
            <div className="viewAssetWrapper">
                <div className="assetWrapper">
                    <img className="creationImage" src={`https://ucarecdn.com/${pageData.asset.imageId}/`} alt={pageData.asset.name} />
                    <div className="contextWrapper">
                        <h1 className="assetTitle">Asset: {pageData.asset.name}</h1>
                        {!pageData.asset.moderated && <span style={{color: "red"}}>Not yet moderated.<br /></span>}
                        <span className="creationCreator">Uploaded by <Link to={`/view-profile/${pageData.user.uid}`}>{pageData.user.username}</Link></span>
                    </div>
                </div>
                <div className="allComments">
                    <h2>Comments</h2>
                    {pageData.comments.map((comment) => {
                        return <ViewComment comment={comment} key={comment.id}/>
                    })}
                    {userContext != null && 
                        <CommentForm onSubmit={(text) => {
                            addComment({text, type: "asset", id: urlParams.id}).then(console.log).catch(console.log);
                        }} />
                    }
                </div>
            </div>
        </div>
    )
}