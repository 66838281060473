import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button } from '../components/form/Button';
import { ImageGallery } from '../components/ImageGallery';
import { Navbar } from '../components/Navbar';
import { functions } from '../firebase';
import { Creation } from '../model/Creation';
import { Group } from '../model/Group';

const getInviteLink = httpsCallable(functions, "getInviteLink");
const getViewGroupPageData = httpsCallable(functions, "getViewGroupPageData");

export type ViewGroupPageData = {
    creations: Creation[];
    group: Group;
};

export const ViewGroupPage = () => {

    const urlParams = useParams();

    const [link, setLink] = useState("");
    const [pageData, setPageData] = useState<ViewGroupPageData>();

    useEffect(() => {
        getViewGroupPageData({id: urlParams.id}).then((res) => setPageData(res.data as ViewGroupPageData));
    }, [urlParams.id]);

    if (pageData == null) {
        return <span>Loading...</span>
    }

    return (
        <div>
            <Navbar />
            <h1>Group {pageData.group.name}</h1>
            {!pageData.group.moderated && <span style={{color: "red"}}>Not yet moderated<br /></span>}
            {pageData.creations.length === 0 && <span>No creations yet<br /></span>}
            <ImageGallery images={pageData.creations} type="creation" />
            {pageData.group.permissions?.includes("owner") && pageData.group.moderated &&
                <div>
                    {link === "" && 
                         <Button type="button" onClick={() => {
                                getInviteLink({groupId: urlParams.id}).then((res) => {
                                setLink(`http://localhost/invite/${(res.data as any).id}`);
                            }).catch(console.log)
                        }} >Create Link</Button>
                    }
                    {link !== "" && 
                        <span>{link}</span>
                    }
                </div>
            }
            </div>
                       
    )
}