import { httpsCallable } from 'firebase/functions';
import React, { useState } from 'react'
import { Button } from '../components/form/Button';
import { functions } from '../firebase';
import { Navbar } from '../components/Navbar'

const makeUserAdmin = httpsCallable(functions, "makeUserAdmin");

export const MakeAdminPage = () => {
    
    const [id, setId] = useState("");

    return (
        <div>
            <Navbar />
            <div className="formWrapper">
                <div className ="form">
                    <h1 className="formTitle">Invite New Admin</h1>
                    <input value={id} onChange={(e) => setId(e.target.value ?? "")}></input>
                    <Button type="button" onClick={() => {
                        makeUserAdmin({id: id}).then(() => setId("")).catch(console.log);
                    }}>Invite</Button>
                </div>
            </div>
        </div>
    )
}