import {FileInfo, Widget} from '@uploadcare/react-widget';
import React, {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form';
import {Asset} from '../model/Asset';
import {Creation} from '../model/Creation';
import {Group} from '../model/Group';
import {Library} from '../model/Library';
import {Button} from './form/Button';
import {InputLabel} from './form/InputLabel';

export type NewCreationFormProps = {
    ownedAssets: Asset[];
    libraries: Library[];
    groups: Group[];
    parent: Creation | undefined;
    onSubmit: (image: FileInfo, creationDetails: CreationDetails) => void;
}

export type CreationDetails = {
    name: string;
    description: string;
    visibility: string;
    assets: string[];
    group?: string;
    parent: string | undefined;
};

// TODO: Use names instead of IDs
export const NewCreationForm: React.FC<NewCreationFormProps> = ({ownedAssets, libraries, groups, parent, onSubmit}: NewCreationFormProps) => {

    const {register, setValue, handleSubmit, getValues, watch} = useForm<CreationDetails>();
    const [useGroup, setUseGroup] = useState(false);
    const [image, setImage] = useState<FileInfo>();
    const [selectedAsset, setSelectedAsset] = useState(0);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [selectedLibrary, setSelectedLibrary] = useState(0);
    const [libraryAssets, setLibraryAssets] = useState<Asset[]>([]);
    const [assets, setAssets] = useState<Asset[]>([]);
    const [canBePublic, setCanBePublic] = useState(true);
    const [mustHaveGroup, setMustHaveGroup] = useState(false);
    const visibilityChanged = watch("visibility");

    useEffect(() => {
        const _libraryAssets = ownedAssets.filter((it) => it.library === libraries[selectedLibrary].id);
        setLibraryAssets(_libraryAssets);
        setSelectedAsset(0);
    }, [selectedLibrary, ownedAssets, libraries]);

    const isPublic = useCallback((asset: Asset): boolean => {
        const library = libraries.find((library) => library.id === asset.library);
        return library?.visibility === "public";
    }, [libraries]);

    const calculateCanBePublic = useCallback(() => {
        // No public libraries to provide assets, so can't be public
        if (!libraries.some((library) => library.visibility === "public")) {
            setCanBePublic(false);
            return;
        }
        // Parent is not public, so this creation cannot be public
        if (parent != null && parent.visibility !== "public") {
            setCanBePublic(false);
            return;
        }

        // Cannot be public if part of a private group
        if (useGroup && groups.at(selectedGroup)?.visibility === "private") {
            setCanBePublic(false);
            setValue("visibility", "private");
            return;
        }

        // If any of the used assets are private, this creation cannot be public
        setCanBePublic(!assets.some((asset) => !isPublic(asset)));
    }, [assets, parent, isPublic, libraries, selectedGroup, groups, useGroup, setValue]);


    // Available groups are those with at least one library and one asset
    const getAvailableGroups = useCallback(() => {
        // mustHaveGroup being true means that this creation must have the same
        // group as its parent creation
        if (mustHaveGroup) {
            const group = groups.find((group) => group.id === parent?.group)!!;
            return [group];
        }
        return groups.filter((group) => {
            return libraries
                .filter((library) => library.group === group.id)
                .some((library) => ownedAssets
                    .some((asset) => asset.library === library.id));
        });
    }, [groups, libraries, ownedAssets, parent, mustHaveGroup]);

    const getAvailableLibraries = useCallback(() => {
        let availableLibraries = libraries;
        // If a group is selected, only show libraries from that group
        if (useGroup) {
            availableLibraries = availableLibraries.filter((library) =>
                library.group === groups[selectedGroup]?.id);
        }

        // If the creation is currently set to public, only show public libraries
        if (getValues("visibility") === "public") {
            availableLibraries = availableLibraries.filter((library) =>
                library.visibility === "public");
        }
        // Update the selected library if the currently selected one is no longer available
        const first = availableLibraries.at(0);
        const current = libraries.at(selectedLibrary);
        if (current == null || (first !== current && !availableLibraries.includes(current))) {
            if (first != null) {
                setSelectedLibrary(libraries.indexOf(first));
            }
        }
        return availableLibraries;
    }, [useGroup, libraries, groups, selectedGroup, getValues, selectedLibrary]);

    useEffect(calculateCanBePublic, [assets, parent, calculateCanBePublic, visibilityChanged]);

    useEffect(() => {
        if (parent != null) {
            setValue("parent", parent.id);
            if (parent.group != null) {
                const groupIdx = groups.findIndex((group) => parent.group === group.id)!!;
                const group = groups.at(groupIdx)!!;
                if (parent.visibility === "private" || group.visibility === "private") {
                    setUseGroup(true);
                    setMustHaveGroup(true);
                    setValue("group", group.id);
                    setSelectedGroup(groupIdx);
                }
            }
        }
    }, [parent, setValue, groups]);

    return (
        // TODO: Show an error if no image uploaded
        <form className="form" onSubmit={handleSubmit((details) => {
            if (image != null && assets.length > 0)
                onSubmit(image, {
                    ...details,
                    assets: assets.map((asset) => asset.id),
                })
        })}>
            <h1>Upload New Creation</h1>
            <Widget
                imagesOnly
                systemDialog
                publicKey="a1709c547cabf8e5e91b"
                onChange={setImage}
            />
            <InputLabel required>Name</InputLabel>
            <input {...register("name", {required: true})} />

            <InputLabel required>Description</InputLabel>
            <textarea {...register("description", {required: true})} />
            {getAvailableGroups().length !== 0 &&
                <div>
                    <input type="checkbox" disabled={mustHaveGroup} checked={useGroup} onChange={(e) => {
                        setUseGroup(e.target.checked);
                        if (e.target.checked) {
                            setValue("group", groups[selectedGroup].id);
                        } else {
                            setValue("group", undefined);
                        }
                    }} />
                    <InputLabel>Use Group</InputLabel>
                </div>
            }

            {useGroup &&
                <select value={selectedGroup} onChange={(e) => {
                    setValue("group", groups[parseInt(e.target.value)].id);
                    setSelectedGroup(parseInt(e.target.value));
                }}>
                    {groups && getAvailableGroups().map((group, idx) => {
                        return <option key={group.id} value={idx}>{group.name}</option>;
                    })}
                </select>}

            <br />
            <InputLabel required>Assets used</InputLabel>

            {/* Select a Library */}
            <select value={selectedLibrary} onChange={(event) => {
                setSelectedLibrary(parseInt(event.target.value));
            }}>
                {libraries && getAvailableLibraries().map((library) => {
                    return <option key={library.id} value={libraries.indexOf(library)}>{library.name}</option>;
                })}
            </select>

            {/* Choose an asset from that library */}
            <select value={selectedAsset} onChange={(event) => {
                setSelectedAsset(parseInt(event.target.value));

            }}>
                {libraryAssets && libraryAssets.map((asset, idx) => {
                    return <option key={asset.id} value={idx}>{asset.name}</option>;
                })}
            </select>

            {/* Add that asset to the selected assets if it does not already exist */}
            <Button type="button" onClick={() => {
                if (!assets.includes(libraryAssets[selectedAsset])) {
                    setAssets((prev) => [...prev, libraryAssets[selectedAsset]]);
                }
            }}>Add</Button>

            {/* Show selected assets and provide a button to remove them */}
            <ul>
                {assets.map((asset) => {
                    return <li key={asset.id}>
                        {asset.name}
                        <Button type="button" onClick={() => {
                            setAssets((prev) => prev.filter((it) => it !== asset))
                        }}>Remove</Button>
                    </li>
                })
                }
            </ul>

            <InputLabel required>Visibility</InputLabel>
            <select {...register("visibility", {required: true})}>
                <option value="private">Private</option>
                {canBePublic && <option value="public">Public</option>}
            </select>

            <Button type="submit">Submit</Button>
        </form>
    )
}
