import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Asset } from '../model/Asset';
import { Creation } from '../model/Creation';
import { Group } from '../model/Group';
import { Button } from './form/Button';

export type ModerationQueueProps = {
    onSave: (approved: string[], denied: string[]) => void;
    moderationQueue: Asset[] | Creation[] | Group[] | any[];
    type: string;
}

export const ModerationQueue: React.FC<ModerationQueueProps> = ({ onSave, moderationQueue, type }: ModerationQueueProps) => {
    const [approved, setApproved] = useState<string[]>([]);
    const [denied, setDenied] = useState<string[]>([]);

    const approve = (id: string) => {
        const isApproved = approved.includes(id);
        const isDenied = denied.includes(id);
        if (!isApproved) {
            setApproved((prev) => [...prev, id]);
        }

        if (isDenied) {
            setDenied((prev) => prev.filter((it) => it !== id));
        }

    };

    const deny = (id: string) => {
        const isApproved = approved.includes(id);
        const isDenied = denied.includes(id);

        if (!isDenied) {
            setDenied((prev) => [...prev, id]);
        }

        if (isApproved) {
            setApproved((prev) => prev.filter((it) => it !== id));
        }

    };

    if (moderationQueue.length > 0) {
        return (
            <div>
                <ul>
                    {moderationQueue.map((data) => {
                        const isApproved = approved.includes(data.id);
                        const isDenied = denied.includes(data.id);
                        return (
                            <li key={data.id}>
                                <div className="modQueueWrapper">
                                    <Link className="modQueueLink" to={`/view-${type}/${data.id}`}>{data.name ?? data.username ?? data.text}</Link>
                                    <div className="modButtons">
                                        <Button type="button" disabled={isApproved} onClick={() => approve(data.id)}>Approve</Button>
                                        <Button type="button" disabled={isDenied} onClick={() => deny(data.id)}>Deny</Button>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <Button type="button" onClick={() => { 
                    onSave(approved, denied);
                    setApproved([]);
                    setDenied([]);
                }}>Save</Button>
            </div>
        )

    }

    return <span>Nothing to moderate</span>

}