import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Group } from '../model/Group';
import { Button } from './form/Button';
import { InputLabel } from './form/InputLabel';

export type NewLibraryFormProps = {
    groups: Group[];
    onSubmit: (libraryDetails: LibraryDetails) => void; 
}

export type LibraryDetails = {
    name: string;
    description?: string;
    visibility: string;
    group?: string;
}

export const NewLibraryForm: React.FC<NewLibraryFormProps> = ({ groups, onSubmit } : NewLibraryFormProps) => {
    
    const {register, handleSubmit, setValue} = useForm<LibraryDetails>();
    const [useGroup, setUseGroup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(0);

    return (
        <div className="formWrapper">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <InputLabel required>Name</InputLabel>
            <input {...register("name", {required: true, maxLength:20})} />
            
            <InputLabel>Description</InputLabel>
            <textarea {...register("description")}/>
            {groups.length !== 0 && 
                <div>
                    <input type="checkbox" checked={useGroup} onChange={(e) => {
                        setUseGroup(e.target.checked);
                        if (e.target.checked) {
                            setValue("group", groups[selectedGroup].id);
                        } else {
                            setValue("group", undefined);
                        }
                    }}/>
                    <InputLabel>Use Group</InputLabel>
                </div>
            }
            {useGroup &&
             <select value={selectedGroup} onChange={(e) => {
                 setValue("group", groups[parseInt(e.target.value)].id);
                 setSelectedGroup(parseInt(e.target.value));
             }}>
               {groups && groups.map((group, idx) => {
                   return <option key={group.id} value={idx}>{group.name}</option>;
               })} 
            </select> }
            {useGroup && <br />}

            <InputLabel required>Visibility</InputLabel>
            <select {...register("visibility", {required: true})}>
                <option value="private">Private</option>
                {(!useGroup || groups.at(selectedGroup)?.visibility === "public") && <option value="public">Public</option>}
            </select>
            <Button type="submit">Submit</Button>
        </form>
        </div>
    )
}