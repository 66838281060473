import React from 'react'
import { Link } from 'react-router-dom'
import { Comment } from '../model/Comment'

export type ViewCommentProps = {
    comment: Comment;
}

export const ViewComment: React.FC<ViewCommentProps> = ({comment} : ViewCommentProps) => {
    return (
        <div className="commentItself">
            <div className= "commentMaker">
                <Link to={`/view-profile/${comment.user.uid}`}> {comment.user.username}: </Link>
            </div>
            <div className="commentText">
                {comment.text}
            </div>
        </div>
    )
}