import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from '../../App'

export type RequireLoginProps = {
    children: JSX.Element;
}

export const RequireLogin: React.FC<RequireLoginProps> = ({ children }: RequireLoginProps) => {

    const user = useContext(UserContext);
    // If not logged in
    if (user == null) {
        // Redirect to login page
        return <Navigate to="/login" />;
    }

    return children;
}