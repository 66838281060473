import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {UserContext} from '../App';

export const Navbar = () => {
    const user = useContext(UserContext);
    return (
        <div className="navbar">
            <span><Link className="navlink" to="/">Mix Mash Match - {user == null ? "Guest" : user.username}</Link></span>
            {user != null ?
                <div>
                    <Link className="navlink" to={`/view-profile/${user.uid}`}>Profile</Link>|
                    <Link className="navlink" to={`/requests`}>Requests</Link>|
                    <Link className="navlink" to="/upload-asset">Upload</Link>|
                    <Link className="navlink" to="/create">New Creation</Link>|
                    <Link className="navlink" to="/new-group">New Group</Link>|
                    <Link className="navlink" to="/new-library">New Library</Link>|
                    <Link className="navlink" to="/logout">Logout</Link>
                </div>
                :
                <div>
                    <Link className="navlink" to="/login">Login</Link>|
                    <Link className="navlink" to="/register">Register</Link>
                </div>
            }
        </div>
    )
}
