import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../App';

export type RequireGuestProps = {
    children: JSX.Element;
}

export const RequireGuest: React.FC<RequireGuestProps> = ({ children }: RequireGuestProps) => {

    const user = useContext(UserContext); 
    // If logged in
    if (user != null) {
        // Redirect to home page
        return <Navigate to="/" />;
    }

    return children;
}