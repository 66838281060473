import React from 'react'

export type InputLabelProps = {
    required?: boolean;
    children: string;
}

export const InputLabel: React.FC<InputLabelProps> = ({ required = false, children }: InputLabelProps) => {
    return (
        <label className='inputSection' style={labelStyle}>{children}{required && <span style={requiredStyle}>*</span>}</label>
    )
}

// Write this here or import a css file
const labelStyle = {

};

const requiredStyle = {
    color: "red"
}
