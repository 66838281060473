import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { Creation } from '../model/Creation';
import { Button } from '../components/form/Button';
import { User } from '../model/User';
import { UserContext } from '../App';
import { Navbar } from '../components/Navbar';
import { ImageGallery } from '../components/ImageGallery';
import { Group } from '../model/Group';
import { Library } from '../model/Library';

const getViewProfilePageData = httpsCallable(functions, "getViewProfilePageData");
const sendFriendRequest = httpsCallable(functions, "sendFriendRequest");
const removeFriend = httpsCallable(functions, "removeFriend");

type ViewProfilePageData = {
    creations: Creation[];
    groups: Group[];
    libraries: Library[];
    user: User; 
};

export const ViewProfilePage = () => {

    const urlParams = useParams();
    const user = useContext(UserContext);

    const [pageData, setPageData] = useState<ViewProfilePageData>();

    const fetchPageData = () => {
        getViewProfilePageData({uid: urlParams.id})
        .then((res) => { 
            setPageData(res.data as ViewProfilePageData);
        })
        .catch(console.log);
    };


    useEffect(fetchPageData, [urlParams.id])

    if (pageData) {
        if (!pageData.user) {
            return <Navigate to="/404"/>
        }
        return (
            <div>
                <Navbar />
                <div className="userProfile">
                    <h1 className="userName">User: {pageData.user.username}</h1>
                    <h2 className="userBio">Bio:</h2>
                    {user != null && !pageData.user.isFriend && !pageData.user.isCurrent && 
                    <Button type="button" onClick={() => {
                        sendFriendRequest({uid: urlParams.id}).then(console.log).catch(console.log);
                    }}>Add Friend</Button>}
                    {user != null && pageData.user.isFriend && 
                    <Button type="button" onClick={() => {
                        removeFriend({uid: urlParams.id}).then(fetchPageData).catch(console.log);
                    }}>Remove Friend</Button>}
                </div>
                {pageData.user.isCurrent && pageData.libraries && 
                    <div className="userLibraries">
                        <h2>Your Libraries:</h2>
                        <Link className="imageViewHere" to="/new-library">Create New Library</Link>
                        {pageData.libraries.map((library) => {
                            return <Link className="imageViewHere" key={library.id} to={`/view-library/${library.id}`}>{library.name}<br /></Link>
                        })}
                    </div>
                }
                {pageData.user.isCurrent && pageData.groups && 
                    <div className="userGroups">
                        <h2>Your Groups:</h2>
                        <Link className="imageViewHere" to="/new-group">Create New Group</Link>
                        {pageData.groups.map((group) => {
                            return <Link key={group.id} to={`/view-group/${group.id}`}>{group.name}</Link>
                        })}
                    </div>
                }
                <div className="profileCreations">
                    <h1 className="profileCreationsTitle">{pageData.user.username}'s Creations </h1>
                    <Link className="imageViewHere" to="/create">Upload New Creation</Link>
                    <ImageGallery images={pageData.creations} type="creation" />
                </div>
            </div>
        )
    }
    return <span>Loading</span>;
}