import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from '../../App'
import { auth } from '../../firebase'

export type RequireVerifiedProps = {
    children: JSX.Element;
}

export const RequireVerified: React.FC<RequireVerifiedProps> = ({ children }: RequireVerifiedProps) => {

    const user = useContext(UserContext);
    // If not logged in
    if (user == null) {
        // Redirect to login page
        return <Navigate to="/login" />;
    }
    
    if (!user.moderated ||!auth.currentUser?.emailVerified) {
        // TODO: Show feedback / verify page
        console.log(`Verify: ${auth.currentUser?.emailVerified} Moderated: ${user.moderated}`)
        return <Navigate to="/"/>;
    }

    return children;
}