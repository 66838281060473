import { httpsCallable } from 'firebase/functions'
import React, { useEffect, useState } from 'react'
import { ImageGallery } from '../components/ImageGallery';
import { Navbar } from '../components/Navbar'
import { functions } from '../firebase'
import { Creation } from '../model/Creation';

const getCreations = httpsCallable(functions, "getCreations");
export const HomePage = () => {

    const [creations, setCreations] = useState<Creation[]>([]);

    useEffect(() => {
        getCreations().then((res) => setCreations(res.data as Creation[]));
    }, []);

    return (
        <div>
            <Navbar />
            {creations &&
                <div className="homePageWrapper">
                    <ImageGallery images={creations} type="creation"/>
                </div>
            } 
        </div>
    )
}