import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from 'react-router-dom';
import { functions } from '../firebase';

const acceptInviteLink = httpsCallable(functions, "useInviteLink");

export const AcceptInvitePage = () => {
    const urlParams = useParams();
    const [group, setGroup] = useState<any>(null);
    useEffect(() => {
        acceptInviteLink({inviteId: urlParams.id}).then((res) => setGroup(res.data)).catch(console.log);
    }, [urlParams.id]);
    if (group != null) {
        return <Navigate to={`/view-group/${group.group}`}/>
    }
    return (
        <div>
           Loading... 
        </div>
    )
}