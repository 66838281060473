import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { isCommaListExpression } from 'typescript';
import { ImageGallery } from '../components/ImageGallery';
import { Navbar } from '../components/Navbar';
import { functions } from '../firebase';
import { Asset } from '../model/Asset';
import { Creation } from '../model/Creation';

const getHistory = httpsCallable(functions, "getHistory");

type CreationHistory = {
    creation: Creation;
    assets: Asset[];
    parent?: CreationHistory;
}

export const ViewHistoryPage = () => {
    const urlParams = useParams();
    const [history, setHistory] = useState<CreationHistory>();
    const [creations, setCreations] = useState<Creation[]>([]);
    const [currentId, setCurrentId] = useState(urlParams.id ?? "");
    const [current, setCurrent] = useState<CreationHistory>();

    useEffect(() => {
        getHistory({id: urlParams.id}).then((res) => setHistory(res.data as CreationHistory)).catch(console.log);
    }, [urlParams]);
    

    useEffect(() => {
        if (history == null) {
            return;
        }
        setCreations([]);
        let current: CreationHistory | undefined = history;
        const cList = [current.creation];
        while ((current = current.parent) != null)  {
            cList.push(current.creation);
        }
        setCreations(cList);
    }, [history]);
    
    useEffect(() => {
        if (history == null) {
            return;
        }
        let current: CreationHistory | undefined = history;
        do {
            if (current.creation.id === currentId) {
                setCurrent(current);
                return;
            }
        } while ((current = current.parent) != null);
        
    }, [currentId, history])

    if (history == null) {
        return <span><Navbar/>Loading...</span>
    }



    return (
        <div>
            <Navbar />
            <h1>History of <Link to={`/view-creation/${urlParams.id}`}>{history.creation.name}</Link></h1>
            {creations.map((creation) => {
                return <span> <a href="#" onClick={() => {setCurrentId(creation.id)}}>{creation.name}</a>{"<--"}</span>
            })}
            <br />
            <h2>Creation {current?.creation.name}</h2>
            <Link to={`/view-creation/${current?.creation.id}`}>
            <img style={{width: "10%"}} src={`https://ucarecdn.com/${current?.creation.imageId}/`} alt={current?.creation.name} />
            </Link>
            <h2>Used assets</h2>
            {current && <ImageGallery type="asset" images={current.assets} />}
        </div>
    )
}