import { httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../App';
import { CommentForm } from '../components/CommentForm';
import { Button } from '../components/form/Button';
import { Navbar } from '../components/Navbar';
import { functions } from '../firebase';
import { Creation } from '../model/Creation';
import { Comment } from '../model/Comment';
import { ViewComment } from '../components/ViewComment';
import { Visibility } from '../model/Visibility';
import { User } from '../model/User';

const getViewCreationPageData = httpsCallable(functions, "getViewCreationPageData");
const addComment = httpsCallable(functions, "addComment");
const updateCreationVisibility = httpsCallable(functions, "updateCreationVisibility");

type ViewCreationPageData = {
    creation: Creation;
    comments: Comment[];
    user: User;
}

export const ViewCreationPage = () => {

    const urlParams = useParams();
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const [pageData, setPageData] = useState<ViewCreationPageData>();
    const [publishError, setPublishError] = useState(false);

    const fetchPageData = () => {
        getViewCreationPageData({ id: urlParams.id })
        .then((res) => setPageData(res.data as ViewCreationPageData))
        .catch(console.log); // TODO: Errors
    };
    
    useEffect(fetchPageData, [urlParams.id]);

    if (pageData == null) {
        return <span>Loading...</span>
    }

    if (!pageData.creation) {
        return <div className="fourZeroFourWrapper">
        <h1 className="fourZeroFour">404</h1>
        <p className="notFoundText">This page could not be found, return to the main page <Link to="/">here.</Link></p>
    </div>;
    }

    return (
        <div>
            <Navbar/>
            <div className="creationPageWrapper">
                <div className="creationDescriptionAndButtonsWrapper">
                    <div className="creationWrapper">
                        <img className="creationImage" src={`https://ucarecdn.com/${pageData.creation.imageId}/`} alt={pageData.creation.name} />
                        <div className="creationInformation">
                            <h1 className ='creationTitle' style={{display: 'block'}}>Creation: {pageData.creation.name}</h1>
                            <span className ="creationCreator">by <Link to={`/view-profile/${pageData.user.uid}`}>{pageData.user.username}</Link></span>
                            {!pageData.creation.moderated && <span className="unmoderated" style={{color: "red"}}>Not yet moderated<br /></span>}
                            <span className="creationDescription" style={{display: 'block'}}>{pageData.creation.description}<br /></span>
                        </div>
                    </div>
                    <div className="creationButtons">
                        <Button type="button" onClick={() => {
                            navigate(`/create/${urlParams.id}`)
                        }}>Create Derivative</Button>
                        <Button type="button" onClick={() => {
                            navigate(`/view-history/${pageData.creation.id}`);
                        }}>History</Button>
                    </div>
                </div>
                <div className="allComments">
                    <h2 className="commentsHeader">Comments</h2>
                    {pageData.comments.map((comment) => {
                        return <ViewComment comment={comment} key={comment.id}/>
                    })}

                    {userContext != null && 
                        <CommentForm onSubmit={(text) => {
                            addComment({text, type: "creation", id: urlParams.id}).then(fetchPageData).catch(console.log);
                        }} />
                    }
                </div>

                {publishError && <span style={{color: "red"}}>Cannot publish a creation that uses private assets<br /></span>}
                {userContext != null && userContext.uid === pageData.creation.user &&
                    <Button type="button" onClick={() => {
                        updateCreationVisibility({ 
                            id: urlParams.id,
                            visibility: pageData.creation.visibility === "public" ? "private" : "public"
                        })
                        .then((res) => {
                            if ((res.data as any).result !== "Success") {
                                setPublishError(true);
                            } else {
                                fetchPageData();
                            }
                        });
                    }}>{pageData.creation.visibility === "public" ? "Unpublish" : "Publish" }</Button>
                    
                }
            </div>
        </div>
    )
}