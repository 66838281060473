import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { RegisterPage } from './pages/RegisterPage';
import { RequireGuest } from './components/route/RequireGuest';
import { RequireLogin } from './components/route/RequireLogin';
import { HomePage } from './pages/HomePage';
import { auth, functions } from './firebase';
import { LogoutPage } from './pages/LogoutPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { UploadAssetPage } from './pages/UploadAssetPage';
import { ViewAssetPage } from './pages/ViewAssetPage';
import { ModerationQueuePage } from './pages/ModerationQueuePage';
import { NewCreationPage } from './pages/NewCreationPage';
import { NewLibraryPage } from './pages/NewLibraryPage';
import { ViewCreationPage } from './pages/ViewCreationPage';
import { NewGroupPage } from './pages/NewGroupPage';
import { ViewGroupPage } from './pages/ViewGroupPage';
import { AcceptInvitePage } from './pages/AcceptInvitePage';
import { ViewProfilePage } from './pages/ViewProfilePage';
import { AcceptFriendRequestPage } from './pages/AcceptFriendRequestPage';
import { User } from './model/User';
import { httpsCallable } from 'firebase/functions';
import { RequireAdmin } from './components/route/RequireAdmin';
import { RequireVerified } from './components/route/RequireVerified';
import { ViewLibraryPage } from './pages/ViewLibraryPage';
import { ViewRequestsPage } from './pages/ViewRequestsPage';
import { ViewHistoryPage } from './pages/ViewHistoryPage';
import { ResendVerificationEmailPage } from './pages/ResendVerificationEmailPage';
import { MakeAdminPage } from './pages/MakeAdminPage';

export const UserContext = React.createContext<User | null>(null);
const getUser = httpsCallable(functions, "getUser");

function App() {

  // Wait for firebase to check auth status
  const [authStateChanged, setAuthStateChanged] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  useEffect(() => {
    auth.onAuthStateChanged((e) => {
      if (e == null) {
        setAuthStateChanged(true);
        setUser(null);
        return;
      }
      getUser({uid: e.uid}).then((res) => {
        setUser(res.data as User);
      })
      .catch(console.log)
      .finally(() => setAuthStateChanged(true));

    });
  }, []);



  if (!authStateChanged) {
    return <span>Loading</span>;
  }

  return (
    <UserContext.Provider value={user}>
      <Routes>
        <Route path="/login" element={<RequireGuest><LoginPage /></RequireGuest>} />
        <Route path="/logout" element={<RequireLogin><LogoutPage /></RequireLogin>} />
        <Route path="/register" element={<RequireGuest><RegisterPage /></RequireGuest>} />

        <Route path="/" element={<HomePage />} />
        <Route path="/upload-asset" element={<RequireVerified><UploadAssetPage /></RequireVerified>} />
        <Route path="/create/:parent" element={<RequireVerified><NewCreationPage /></RequireVerified>} />
        <Route path="/create" element={<RequireVerified><NewCreationPage /></RequireVerified>} />
        <Route path="/resend-verification-email" element={<RequireLogin><ResendVerificationEmailPage /></RequireLogin>} />
        <Route path="/new-group" element={<RequireVerified><NewGroupPage /></RequireVerified>} />
        <Route path="/new-library" element={<RequireVerified><NewLibraryPage /></RequireVerified>} />
        <Route path="/requests" element={<RequireVerified><ViewRequestsPage /></RequireVerified>} />
        <Route path="/view-asset/:id" element={<ViewAssetPage />} />
        <Route path="/view-group/:id" element={<RequireLogin><ViewGroupPage /></RequireLogin>} />
        <Route path="/invite/:id" element={<RequireVerified><AcceptInvitePage /></RequireVerified>} />
        <Route path="/friend-request/:id" element={<RequireVerified><AcceptFriendRequestPage /></RequireVerified>} />
        <Route path="/view-creation/:id" element={<ViewCreationPage />} />
        <Route path="/view-profile/:id" element={<ViewProfilePage />} />
        <Route path="/view-history/:id" element={<ViewHistoryPage />} />
        <Route path="/view-library/:id" element={<ViewLibraryPage />} />
        <Route path="/admin/moderation-queue" element={<RequireAdmin><ModerationQueuePage /></RequireAdmin>} /> 
        <Route path="/admin/make" element={<RequireAdmin><MakeAdminPage /></RequireAdmin>} /> 

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </UserContext.Provider>
  );
}

export default App;
