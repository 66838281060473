import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react'
import { functions } from '../firebase';

const getVerificationLink = httpsCallable(functions, "getVerificationLink");

export const ResendVerificationEmailPage  = () => {
    const [link, setLink] = useState("");
    useEffect(() => {
        getVerificationLink().then((res) => setLink((res.data as any).link)).catch(console.log);
    }, []);
    if (link === "") {
        return <span>Loading...</span>
    }
    return (
        <span>{link}</span>
    )
}