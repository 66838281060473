import React from 'react'
import {Visibility} from '../model/Visibility';
import { useForm } from 'react-hook-form';
import { Button } from './form/Button';
import { InputLabel } from './form/InputLabel';

export type NewGroupFormProps = {
    onSubmit: (details: GroupDetails) => void; 
}

export type GroupDetails = {
    name: string;
    description: string;
    visibility: Visibility; 
}

export const NewGroupForm: React.FC<NewGroupFormProps> = ({onSubmit} : NewGroupFormProps) => {
    const {register, handleSubmit} = useForm<GroupDetails>();

    return (
        <div className="formWrapper">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <h1 className="formTitle">Create New Group</h1>
                <InputLabel required>Name</InputLabel>
                <input {...register("name", {required: true, maxLength:20})} />
                
                <InputLabel>Description</InputLabel>
                <textarea {...register("description")}/>

                <InputLabel required>Visibility</InputLabel>
                <select {...register("visibility", {required: true})}>
                    <option value="private">Private</option>
                    <option value="public">Public</option>
                </select>
                <Button type="submit">Submit</Button>
            </form>
        </div>
    );
}