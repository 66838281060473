import { httpsCallable } from 'firebase/functions'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AssetUploadForm } from '../components/AssetUploadForm'
import { Navbar } from '../components/Navbar'
import { functions } from '../firebase'
import { Asset } from '../model/Asset'
import { Library } from '../model/Library'

const createAsset = httpsCallable(functions, "createAsset");
const getLibraries = httpsCallable(functions, "getLibraries")

export const UploadAssetPage = () => {
    const navigate = useNavigate();

    const [libraries, setLibraries] = useState<Library[]>();

    // TODO: Show a loading page
    useEffect(() => {
        // TOOD: Handle errors
        getLibraries()
            .then((result) => {
                const l = result.data as Library[];
                setLibraries(l);
            })
            .catch(console.log);
    }, []);

    if (libraries == null) {
        return <span>Loading...</span>
    }

    if (libraries.length === 0) {
        return <div><Navbar /><span className="uploadHereWrapper"><h1 className="needLibrary">You need a library to store assets.</h1> <Link className="imageViewHere" to="/new-library">Make one here.</Link></span></div>
    }


    return (
        <div>
            <Navbar />
            <AssetUploadForm libraries={libraries} onSubmit={(image, details) => {
                createAsset({
                    ...details,
                    imageId: image.uuid
                }).then((result) => navigate(`/view-asset/${(result.data as Asset).id}`))
            }} />
        </div>
    )
}


