import React from 'react'
import { useForm } from 'react-hook-form';
import { Button } from './form/Button';
import { InputLabel } from './form/InputLabel';
import { Link } from 'react-router-dom'

export type LoginFormProps = {
    onSubmit: (loginDetails: LoginDetails) => void;
}

export type LoginDetails = {
    email: string;
    password: string;
}

export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }: LoginFormProps) => {

    const { register, handleSubmit } = useForm<LoginDetails>();

    return (
        <div className='formWrapper'>
            <form className = "form" onSubmit={handleSubmit(onSubmit)}>
                <h1 className="formTitle">Login</h1>
                <InputLabel required>Email</InputLabel>
                <input type="email" {...register("email", { required: true })} />
                <InputLabel required>Password</InputLabel>
                <input type="password" {...register("password", { required: true })} />
                <Button type="submit">Login</Button>
            </form>
            <p className='registerHere'>No account? <span> <Link to="/register"> Register here.</Link></span></p>
        </div>
    )
}
