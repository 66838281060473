import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from 'react-router-dom';
import { functions } from '../firebase';

const acceptFriendRequest = httpsCallable(functions, "acceptFriendRequest");

export const AcceptFriendRequestPage = () => {
    const urlParams = useParams();
    const [userId, setUserId] = useState<any>(null);
    useEffect(() => {
        acceptFriendRequest({id: urlParams.id}).then((res) => setUserId(res.data)).catch(console.log);
    }, [urlParams.id]);
    if (userId != null) {
        return <Navigate to={`/view-profile/${userId.id}`}/>
    }
    return (
        <div>
           Loading... 
        </div>
    )
}