import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import { NewCreationForm } from '../components/NewCreationForm'
import { functions } from '../firebase';
import { Asset } from '../model/Asset';
import { Creation } from '../model/Creation';
import { Group } from '../model/Group';
import { Library } from '../model/Library';

const newCreation = httpsCallable(functions, "newCreation");
const getNewCreationPageData = httpsCallable(functions, "getNewCreationPageData");

type NewCreationPageData = {
    assets: Asset[];
    libraries: Library[];
    groups: Group[];
    parent?: Creation;
};

export const NewCreationPage = () => {
    
    const params = useParams();
    const [pageData, setPageData] = useState<NewCreationPageData>();
    const navigate = useNavigate();

    useEffect(() => {
        getNewCreationPageData({parent: params["parent"]}).then((res) => {
            setPageData(res.data as NewCreationPageData);
        }).catch(console.log);
    }, []);
    if(!pageData) {
        return <span>Loading...</span>
    }
    if (pageData.assets.length === 0) {
        return <span><Navbar /><div className="uploadHereWrapper"><h1 className="needLibrary">No available (moderated) assets. </h1><Link className="imageViewHere" to="/upload-asset">Upload an asset.</Link></div></span>
    }
    return (
        <div>
            <Navbar/>
            {pageData.parent && <h2>Creating derivative of {pageData.parent.name}<br /></h2>}
            <NewCreationForm parent={pageData.parent} ownedAssets={pageData.assets}
            libraries={pageData.libraries} groups={pageData.groups}
             onSubmit={(image, details) => {
                newCreation({imageId: image.uuid, ...details})
                    .then((res) => navigate(`/view-creation/${(res.data as any).id}`))
                    .catch(console.log);
            }}/> 
        </div>
    )
}