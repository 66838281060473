import { FileInfo, Widget } from '@uploadcare/react-widget'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Button } from './form/Button';
import { InputLabel } from './form/InputLabel';

export type AssetUploadFormProps = {
    libraries: { name: string, id: string }[];
    onSubmit: (image: FileInfo, assetDetails: AssetDetails) => void;
};

type AssetDetails = {
    name: string;
    library: string;
    license: string;
    consent: boolean;
};

export const AssetUploadForm: React.FC<AssetUploadFormProps> = ({ libraries, onSubmit }: AssetUploadFormProps) => {

    const { register, handleSubmit } = useForm<AssetDetails>();
    const [image, setImage] = useState<FileInfo>();

    return (
        // TODO: Show an error if no image uploaded
        <div className="formWrapper">
            <form className="form" onSubmit={handleSubmit((details) => { if (image != null) onSubmit(image, details) })}>
                <Widget
                    imagesOnly
                    systemDialog
                    publicKey="a1709c547cabf8e5e91b"
                    onChange={setImage}
                />
                <InputLabel required>Name</InputLabel>
                <input {...register("name", { required: true })} />
                <InputLabel required>Library</InputLabel>
                <select {...register("library", { required: true })}>
                    {libraries && libraries.map((library) => {
                        return <option key={library.id} value={library.id}>{library.name}</option>;
                    })}
                </select>
                <InputLabel required>License</InputLabel>
                <input {...register("license", { required: true })} />
                <div className="perms">
                    <input className="permCheck" type="checkbox" {...register("consent", { required: true })} />
                    <span>I have permission to upload this image</span>
                </div>
                <Button type="submit">Submit</Button>
            </form >
        </div>
    )
}