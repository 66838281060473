import React from 'react'
import { Library } from '../model/Library'
import { User } from '../model/User'
import { Button } from './form/Button'
import { Navbar } from './Navbar'

export type RequestQueueProps = {
    requests: Request[];
    libraries: Library[];
    users: User[];
    onAccept: (request: Request) => void;
    onDeny: (request: Request) => void;
}

export type Request = {
    id: string;
    type: string;
    from: string;
    to: string;
}

export const RequestQueue: React.FC<RequestQueueProps> = ({requests, libraries, users, onAccept, onDeny} : RequestQueueProps) => {
    if (requests.length === 0) {
        return <span>Nothing here</span>
    }
    return (
        <div>
            {requests.map((request) => {
                return <div id={request.id}>{`${request.type} 
                request from ${request.type === "friend" ? users.find((u) => u.uid === request.from)?.username :
                 libraries.find((l) => l.id === request.from)?.name}`} <Button type="button" onClick={() => {
                     onAccept(request);
                 }}>Accept</Button> </div>;
            })}
        </div>
    )
}