import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react'
import { Navbar } from '../components/Navbar';
import { Request, RequestQueue } from '../components/RequestQueue';
import { functions } from '../firebase';
import { Library } from '../model/Library';
import { User } from '../model/User';

const getViewRequestsPageData = httpsCallable(functions, "getViewRequestsPageData");
const acceptFriendRequest = httpsCallable(functions, "acceptFriendRequest");
const acceptLibraryInvite = httpsCallable(functions, "acceptLibraryInvite");

type ViewRequestsPageData = {
    requests: Request[];
    users: User[];
    libraries: Library[];
};

export const ViewRequestsPage = () => {
    
    const [pageData, setPageData] = useState<any>(null);

    const fetchPageData = () => {
        getViewRequestsPageData().then((res) => setPageData(res.data as ViewRequestsPageData));
    };
    useEffect(fetchPageData, []);

    if (pageData == null) {
        return <span>Loading...</span>
    } 
    return (
        <div>
            <Navbar />
            <RequestQueue requests={pageData.requests}
             libraries={pageData.libraries}
              users={pageData.users}
              onAccept={(request) => {
                  if (request.type === "friend") {
                      acceptFriendRequest({id: request.id}).then(fetchPageData).catch(console.log);
                  } else if (request.type === "library") {
                      acceptLibraryInvite({id: request.id}).then(fetchPageData).catch(console.log);
                  }
              }}
              onDeny={(request) => {/* TODO */}}/>
        </div>
    )
}