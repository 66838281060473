import React from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { LoginForm } from '../components/LoginForm';
import { Navbar } from '../components/Navbar';

// TODO: Styling
export const LoginPage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Navbar />
            <LoginForm onSubmit={({ email, password }) => {
                signInWithEmailAndPassword(auth, email, password)
                    .then(() => navigate("/"))
                    .catch(console.log); // TODO: Handle errors
            }} />
        </div>
    )
}