import React, { useState } from 'react'
import { Button } from './form/Button';

export type CommentFormProps = {
    onSubmit: (text: string) => void;
}

export const CommentForm: React.FC<CommentFormProps> = ({onSubmit} : CommentFormProps) => {
    
    const [text, setText] = useState("");

    return (
        <div>
            <input type="text" onChange={(e) => {
                setText(e.target.value ?? "");
            }}/>
            <Button type="button" onClick={() => {
                onSubmit(text);
            }}>Comment</Button>
        </div>
    )
}