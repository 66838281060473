import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from './form/Button'

export type ImageGalleryProps = {
    images: Image[];
    type: string;
    onDelete?: (id: string) => void;
    showDelete?: boolean;
}

export type Image = {
    id: string;
    imageId: string;
    name: string;
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({images, type, onDelete, showDelete = onDelete != null } : ImageGalleryProps) => {
    return (
        <div className ="imageCardWrapper">
            {images && images.map((image) => {
              return <div className="imageCard" key={image.id} > 
                        <Link key={image.id}to={`/view-${type}/${image.id}`}>
                        <img className="cardImage" key={image.id + "img"} alt={image.name} src={`https://ucarecdn.com/${image.imageId}/`}></img>
                        </Link>
                        <div className="imageCardInfo">
                            <h2 className = "imageTitle">{image.name}</h2> 
                            <Link className="imageViewHere" key={image.id} to={`view-${type}/${image.id}`}>View Here.</Link>
                        </div>
                        {showDelete && onDelete != null && 
                        <Button type="button" onClick={() => {
                            onDelete(image.id);
                        }}>Delete</Button>
                }
                &nbsp;{" "}&nbsp;
            </div>
            })}
        </div>
    )
}