import { httpsCallable } from 'firebase/functions';
import { userInfo } from 'os';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { UserContext } from '../App';
import { Button } from '../components/form/Button';
import { InputLabel } from '../components/form/InputLabel';
import { ImageGallery } from '../components/ImageGallery';
import { Navbar } from '../components/Navbar';
import { NewLibraryForm } from '../components/NewLibraryForm';
import { functions } from '../firebase';
import { Asset } from '../model/Asset';
import { Library } from '../model/Library';

const getViewLibraryPageData = httpsCallable(functions, "getViewLibraryPageData");
const inviteUserToLibrary = httpsCallable(functions, "inviteUserToLibrary");
const deleteAsset = httpsCallable(functions, "deleteAsset");

type ViewLibraryPageData = {
    library: Library;
    assets: Asset[];
}

export const ViewLibraryPage = () => {

    const urlParams = useParams();

    const [pageData, setPageData] = useState<ViewLibraryPageData>();
    const [id, setId] = useState("");
    const [edit, setEdit] = useState(false);
    const [invited, setInvited] = useState(false);
    const [error, setError] = useState(false);

    const fetchPageData = () => {
        getViewLibraryPageData({id: urlParams.id}).then((res) => setPageData(res.data as ViewLibraryPageData))
        .catch(console.log);
    };
    
    useEffect(fetchPageData, [urlParams.id]);

    if (pageData == null) {
        return <span>Loading...</span>
    }

    return (
        <div >
            <Navbar />
            <div className="libraryPageWrapper">
                <div className="libraryWrapper">
                    <h1>Library: {pageData.library.name}</h1>
                    {!pageData.library.moderated && <span style={{color: "red"}}>Not yet moderated.</span>}
                    <div className="libraryInvite inviteForm">
                        <h2 className="inviteUser">Invite User To Library</h2>
                        
                            {!invited && pageData.library.permissions?.includes("owner") && 
                                <div className="inviteField">
                                    <div className="inputCheck">
                                        <input className="editField" onChange={(e) => setId(e.target.value ?? "")}></input>
                                        <InputLabel >Edit</InputLabel>
                                        <input checked={edit} type="checkbox" onChange={() => {
                                            setEdit((it) => !it);
                                        }}/>
                                    </div>
                                    <Button type="button" onClick={() => {
                                        inviteUserToLibrary({id: urlParams.id, uid: id, permissions: ["view", edit ? "edit" : ""]})
                                        .then((result) => {
                                            if ((result.data as any).result === "Success") {
                                                setInvited(true);
                                            }
                                        })
                                        .catch(console.log);
                                    }}>Invite</Button>
                            
                            </div>
        
                        }
                        {invited && pageData.library.permissions?.includes("owner") && 
                            <span>Invite sent</span>
                        }
                     </div>
                </div>
                {error && <span style={{color: "red"}}>You cannot delete an asset that is being used in a creation.</span>}
                <div className="galleryWrapper">    
                    <h1>Library Asset Gallery</h1>
                    <ImageGallery showDelete={pageData.library.permissions?.includes("owner")} onDelete={(id) => {
                        deleteAsset({id}).then((res) => {
                            console.log(res.data)
                            if ((res.data as any).result === "Success") {
                            fetchPageData();
                            } else {
                                setError(true);
                            }
                        }).catch(console.log);
                    }} images={pageData.assets} type="asset"/>
                </div>
        </div>
       </div>
                       
    )
}