import React from 'react'

export type ButtonProps = {
    children: string;
    type: "button" | "submit" | "reset" | undefined;
    disabled?: boolean;
    onClick?: () => void;
}

// TODO: Styling
export const Button: React.FC<ButtonProps> = ({ children, type, disabled = false, onClick }: ButtonProps) => {
    return (
        <button className='inputButton' disabled={disabled} type={type} onClick={onClick}>{children}</button>
    )
}
