import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Button } from './form/Button';
import { InputLabel } from './form/InputLabel';

export type RegisterFormProps = {
    onSubmit: (details: RegistrationDetails) => void;
}

export type RegistrationDetails = {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    birthdate: {
        month: number;
        day: number;
        year: number;
    }
};

// TODO: Styling
export const RegisterForm: React.FC<RegisterFormProps> = ({ onSubmit }: RegisterFormProps) => {

    const { register, formState: { errors }, handleSubmit, getValues } = useForm<RegistrationDetails>();

    return (
        <div className="formWrapper">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <h1 className='formTitle'>Register Account</h1>
                <InputLabel required>First Name</InputLabel>
                <input {...register("firstName", { required: true })} />
                {errors.firstName != null && <span>{errors.firstName.type}</span>}
                <br />
                <InputLabel required>Last Name</InputLabel>
                <input {...register("lastName", { required: true })} />
                {errors.lastName != null && <span>{errors.lastName.type}</span>}
                <br />
                <InputLabel required>Username</InputLabel>
                <input {...register("username", { required: true })} />
                {errors.username != null && <span>{errors.username.type}</span>}
                <br />
                <InputLabel required>Email</InputLabel>
                <input type="email" {...register("email", { required: true })} />
                {errors.email != null && <span>{errors.email.type}</span>}
                <br />
                <InputLabel required>Password</InputLabel>
                <input type="password" {...register("password", { required: true, minLength: 6 })} />
                {errors.password != null && <span>{errors.password.type}</span>}
                <br />
                <InputLabel required>DOB</InputLabel>
                <div>
                    <select {...register("birthdate.month", { required: true })}>
                        <option value="0">January</option>
                        <option value="1">Feburary</option>
                        <option value="2">March</option>
                        <option value="3">April</option>
                        <option value="4">May</option>
                        <option value="5">June</option>
                        <option value="6">July</option>
                        <option value="7">August</option>
                        <option value="8">September</option>
                        <option value="9">October</option>
                        <option value="10">November</option>
                        <option value="11">December</option>
                    </select>
                    <InputLabel required>Day</InputLabel>
                    <input className="tiempo" type="number" {...register("birthdate.day", { required: true, pattern: /^([1-9]|[12]\d|3[01])$/ })} />
                    <InputLabel required>Year</InputLabel>
                    <input className="tiempo" type="number" {...register("birthdate.year", {
                        validate: (_) => {
                            return validateAge(getValues("birthdate"));
                        }, required: true, pattern: /^(19|20)\d{2}$/
                    })} />
                </div>
                <Button type="submit">Register</Button>
            </form>
        </div>
    );

}

const validateAge = ({ day, month, year }: { day: number, month: number, year: number }) => {
    const difference = Date.now() - new Date(year, month, day).getTime();
    const age = Math.floor((difference / (1000 * 3600 * 24)) / 365.25);
    return age >= 13;
}