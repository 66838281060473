import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from '../../App'

export type RequireAdminProps = {
    children: JSX.Element;
}

export const RequireAdmin: React.FC<RequireAdminProps> = ({ children }: RequireAdminProps) => {

    const user = useContext(UserContext);
    // If not logged in
    if (!user?.isAdmin) {
        // Redirect to login page
        return <Navigate to="/"/>;
    }

    return children;
}